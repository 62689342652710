'use client';

import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import Text from 'components/ui/Text';

import { useTheme } from 'hooks/useTheme';

import styles from './Nutrition.module.scss';

const indent = ['Added Sugars'];

const separator = ['Protein'];

const Nutrition = ({
    nutritionInfo = [],
    nutritionInfo: [
        { serving_size = '', servings_per_container, calories, items } = {},
    ],
    allergens,
}) => {
    const [theme] = useTheme();

    if (
        !nutritionInfo ||
        !serving_size ||
        !servings_per_container ||
        !calories ||
        !items
    ) {
        return null;
    }

    return (
        <div className={cx(styles.root, styles[`theme--${theme}`])}>
            <div className={styles.inner}>
                <Text.Theme
                    config={{
                        'makers-reserve': {
                            baseTheme: 'parkinsonMedium',
                            themes: { large: 'parkinsonLarge' },
                        },
                        default: {
                            baseTheme: 'headingSmall',
                            themes: { large: 'headingLarge' },
                        },
                    }}
                    as="h2"
                >
                    Nutrition Facts
                </Text.Theme>
                <ul className={styles.servings}>
                    <Text.Theme
                        as="li"
                        config={{
                            'makers-reserve': {
                                baseTheme: 'parkinsonXSmall',
                                themes: { large: 'parkinsonSmall' },
                            },
                            default: {
                                baseTheme: 'bodyMedium',
                            },
                        }}
                    >
                        Serving Size {serving_size}
                    </Text.Theme>
                    <Text.Theme
                        config={{
                            'makers-reserve': {
                                baseTheme: 'parkinsonXSmall',
                                themes: { large: 'parkinsonSmall' },
                            },
                            default: {
                                baseTheme: 'bodyMedium',
                            },
                        }}
                        as="li"
                    >
                        Servings Per Container {servings_per_container}
                    </Text.Theme>
                    <Text.Theme
                        config={{
                            'makers-reserve': {
                                baseTheme: 'parkinsonXSmall',
                                themes: { large: 'parkinsonSmall' },
                            },
                            default: {
                                baseTheme: 'bodyMedium',
                            },
                        }}
                        as="li"
                    >
                        Calories {calories}
                    </Text.Theme>
                </ul>
                <table>
                    <colgroup>
                        <col className="one" />
                        <col className="two" />
                        <col className="three" />
                    </colgroup>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Amount</th>
                            <th>%DV*</th>
                        </tr>
                    </thead>
                    <tbody>
                        {items.map(
                            ({ name, amount = '', percent = '' }, i) =>
                                name !== '' && (
                                    <tr
                                        className={cx(styles.line, {
                                            [styles.indent]:
                                                indent.includes(name),
                                            [styles.separator]:
                                                separator.includes(name),
                                        })}
                                        key={i}
                                    >
                                        <Text baseTheme="bodyXSmall" as="td">
                                            {name}
                                        </Text>
                                        <Text baseTheme="bodyXSmall" as="td">
                                            {amount}
                                        </Text>
                                        <Text baseTheme="bodyXSmall" as="td">
                                            {percent || ' '}
                                        </Text>
                                    </tr>
                                )
                        )}
                    </tbody>
                </table>
                <div className={styles.pdv}>
                    <Text themes={{ large: 'bodyXSmall' }}>
                        * Percent Daily Values (DV) are based on 2,000 calorie
                        diet.
                    </Text>
                </div>
                <Text
                    as="h4"
                    baseTheme="label"
                    themes={{ large: 'labelLarge' }}
                    className={styles.allergensHeading}
                >
                    Allergens
                </Text>
                {allergens && (
                    <Text
                        baseTheme="body"
                        themes={{ large: 'bodyMedium' }}
                        className={styles.allergensBody}
                        dangerouslySetInnerHTML={{
                            __html: allergens,
                        }}
                    ></Text>
                )}
            </div>
        </div>
    );
};

Nutrition.propTypes = {
    nutritionInfo: PropTypes.array.isRequired,
    allergens: PropTypes.string,
};

export default Nutrition;
