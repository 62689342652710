'use client';

import React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import cx from 'classnames';
import Link from 'next/link';
import PropTypes from 'prop-types';

import { HeaderBottom } from 'components/globals/Header/Header';

import BlockCallout from 'components/blocks/BlockCallout/BlockCallout';
import Accordion, { Child as AccordionChild } from 'components/ui/Accordion';
import Award from 'components/ui/Award';
import ButtonCTA from 'components/ui/ButtonCTA';
import DestiniBuy from 'components/ui/DestiniBuyButton';
import Grid from 'components/ui/Grid';
import Img from 'components/ui/Img';
import SvgIcon from 'components/ui/SvgIcon';
import Text from 'components/ui/Text';

import { useTextAutoContrast } from 'hooks/useTextAutoContrast';
import { getColor } from 'utils';

import Ingredients from './Ingredients';
import Nutrition from './Nutrition';

import styles from './Product.module.scss';

const iconMap = {
    New: 'newPdpRibbonDark',
};

const Product = ({
    blocks,
    icon,
    allergens,
    award,
    awardAsset,
    callout,
    calloutEyebrow,
    category,
    discontinued,
    textColor,
    color,
    displayName,
    eyebrow,
    image,
    ingredients,
    nutritionInfo,
    pageTitle,
    productGroup,
    hideWhereToBuyButton,
    sizes,
    slug,
    theme,
    relatedPages,
}) => {
    blocks ??= null;
    icon ??= '';
    textColor ??= 'Auto';

    let isDark = useTextAutoContrast(color) === 'dark';
    if (textColor === 'Light') {
        isDark = false;
    } else if (textColor === 'Dark') {
        isDark = true;
    }

    return (
        <div className={cx(styles.productPage, styles[`theme--${theme}`])}>
            <HeaderBottom.Source>
                <div className={styles.breadcrumbs}>
                    <Link
                        href={`/products`}
                        className={styles['breadcrumbs__root']}
                    >
                        Products
                    </Link>
                    <span className={styles['breadcrumbs__separator']}>/</span>
                    <Link
                        as={`/products/${category.slug}/all`}
                        href={`/products/[category]/all`}
                        className={styles['breadcrumbs__currentCategory']}
                    >
                        <span className={styles['breadcrumbs__carrot']}>
                            <SvgIcon type={'chevronLeft'} />
                        </span>
                        All {category.title}
                    </Link>
                    <span className={styles['breadcrumbs__separator']}>/</span>
                    <span className={styles['breadcrumbs__currentPage']}>
                        {pageTitle}
                    </span>
                </div>
            </HeaderBottom.Source>
            <div
                className={cx(styles.header, {
                    [styles['header--dark-text']]:
                        isDark || theme === 'makers-reserve',
                })}
                style={{
                    '--product-background-color': getColor(color),
                }}
            >
                <Grid className={styles.headerInner}>
                    {iconMap[icon] && (
                        <div className={styles.iconPlacement}>
                            <div className={styles.icon}>
                                <SvgIcon type={iconMap[icon]} />
                            </div>
                        </div>
                    )}
                    <div className={styles.productImage}>
                        {image?.url && (
                            <TransitionGroup
                                className={cx(
                                    styles.imageContainer,
                                    award && styles.hasAward
                                )}
                            >
                                <CSSTransition
                                    key={slug}
                                    timeout={250}
                                    classNames={{
                                        enter: styles['imageContainer-enter'],
                                        enterActive:
                                            styles[
                                                'imageContainer-enter-active'
                                            ],
                                        enterDone:
                                            styles['imageContainer-done-enter'],
                                        exit: styles['imageContainer-exit'],
                                        exitActive:
                                            styles[
                                                'imageContainer-exit-active'
                                            ],
                                    }}
                                >
                                    <figure className={styles.image}>
                                        <Img
                                            width={image?.width}
                                            height={image?.height}
                                            src={image.url}
                                            customSources={[
                                                {
                                                    breakpoint: 1920,
                                                    src: image.url,
                                                    imageWidth: 1920 * 0.75,
                                                },
                                                {
                                                    breakpoint: 1440,
                                                    src: image.url,
                                                    imageWidth: 1440 * 0.75,
                                                },
                                                {
                                                    breakpoint: 1024,
                                                    src: image.url,
                                                    imageWidth: 1024 * 0.75,
                                                },
                                                {
                                                    breakpoint: 768,
                                                    src: image.url,
                                                    imageWidth: 768 * 0.75,
                                                },
                                                {
                                                    src: image.url,
                                                    imageWidth: 768,
                                                },
                                            ]}
                                            fallbackImageWidth={600}
                                            alt={displayName}
                                            priority
                                        />
                                    </figure>
                                </CSSTransition>
                            </TransitionGroup>
                        )}
                    </div>
                    <div
                        className={cx(styles.titleContainer, {
                            [styles.hasAward]: award || awardAsset?.url,
                        })}
                    >
                        {theme !== 'makers-reserve' && !award && eyebrow && (
                            <div className={styles.eyebrow}>
                                <Text
                                    as="h3"
                                    baseTheme="labelSmall"
                                    themes={{ large: 'labelLarge' }}
                                >
                                    {eyebrow}
                                </Text>
                            </div>
                        )}
                        {(award?.json || awardAsset?.url) && (
                            <div className={styles.awardWrapper}>
                                {!awardAsset?.url && (
                                    <div className={cx(styles.award)}>
                                        <Award richText={award} />
                                    </div>
                                )}
                                {awardAsset?.url && (
                                    <img
                                        className={cx(styles.awardAsset)}
                                        src={awardAsset.url}
                                        alt=""
                                    />
                                )}
                            </div>
                        )}
                        <div className={styles.title}>
                            <Text.Theme
                                as="h1"
                                config={{
                                    'makers-reserve': {
                                        baseTheme: 'parkinsonMedium',
                                        themes: {
                                            medium: 'parkinsonLarge',
                                            large: 'parkinsonXLarge',
                                        },
                                    },
                                    default: {
                                        baseTheme: 'displaySmall',
                                        themes: {
                                            medium: 'displayMedium',
                                            xLarge: 'displayLarge',
                                            xxxLarge: 'displayXLarge',
                                        },
                                    },
                                }}
                            >
                                {displayName}
                            </Text.Theme>
                        </div>

                        {discontinued ? (
                            <div className={styles.discontinued}>
                                <SvgIcon type="caution" />
                                <Text baseTheme="bodySmall">
                                    This product is no longer available
                                </Text>
                            </div>
                        ) : sizes ? (
                            <div className={styles.cta}>
                                {!hideWhereToBuyButton && (
                                    <div className={styles.ctaButtons}>
                                        <DestiniBuy
                                            sku={sizes.map(item => item.sku)}
                                            button={
                                                <ButtonCTA
                                                    text="Where to Buy"
                                                    iconType="location"
                                                    style={
                                                        {
                                                            'makers-reserve':
                                                                'makersFilled-cream-black',
                                                            default: isDark
                                                                ? 'filled-blue-cream'
                                                                : 'filled-cream-white',
                                                        }[theme ?? 'default']
                                                    }
                                                />
                                            }
                                        />
                                    </div>
                                )}

                                <div className={styles.weights}>
                                    <Text
                                        baseTheme="labelSmall"
                                        themes={{ medium: 'labelLarge' }}
                                    >
                                        {sizes
                                            .map(size => size.size)
                                            .join(' / ')}
                                    </Text>
                                </div>
                            </div>
                        ) : null}
                    </div>
                </Grid>
            </div>
            {productGroup?.products?.items && (
                <div className={styles.productVariantsContainer}>
                    <ul className={styles.productVariants}>
                        {productGroup.products.items.map((product, i) => {
                            return (
                                <li key={i}>
                                    <Link
                                        href={`/products/${product.category.slug}/${product.slug}`}
                                        scroll={false}
                                        className={cx(styles.productVariant, {
                                            [styles[
                                                'productVariant--selected'
                                            ]]: slug == product.slug,
                                        })}
                                    >
                                        <Img
                                            src={
                                                product.groupThumbnail?.url ||
                                                product.image.url
                                            }
                                            fallbackImageWidth={150}
                                            loading="eager"
                                            alt={displayName}
                                        />
                                        <Text
                                            baseTheme="labelSmall"
                                            themes={{
                                                medium: 'labelLarge',
                                            }}
                                            className={
                                                styles['productVariant__title']
                                            }
                                        >
                                            {product.groupLabel}
                                        </Text>
                                        {slug == product.slug && (
                                            <div
                                                className={
                                                    styles.productVariantIconChecked
                                                }
                                                aria-hidden="true"
                                                role="presentation"
                                            >
                                                <SvgIcon type="roundCheckAlt" />
                                            </div>
                                        )}
                                    </Link>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            )}
            <div className={styles.content}>
                {callout && (
                    <BlockCallout
                        hasNotch
                        theme="Banner"
                        eyebrow={calloutEyebrow || displayName}
                        roundedCorners={false}
                        richHeadline={{
                            json: {
                                nodeType: 'document',
                                content: [
                                    {
                                        nodeType: 'paragraph',
                                        content: [
                                            {
                                                nodeType: 'text',
                                                value: `${callout}`,
                                                marks: [],
                                            },
                                        ],
                                    },
                                ],
                            },
                        }}
                        colorTheme={theme}
                        __typename="blockCallout"
                    />
                )}

                <Accordion className={styles.accordion}>
                    {nutritionInfo && (
                        <AccordionChild label={'Nutrition & Allergens'}>
                            <Nutrition
                                nutritionInfo={nutritionInfo}
                                allergens={allergens}
                            />
                        </AccordionChild>
                    )}
                    <AccordionChild label={'Ingredients'}>
                        <Ingredients ingredients={ingredients} />
                    </AccordionChild>
                </Accordion>
            </div>
            <div className={styles.blocks}>{blocks}</div>
            {relatedPages}
        </div>
    );
};

Product.propTypes = {
    displayName: PropTypes.string.isRequired,
    pageTitle: PropTypes.string.isRequired,
    discontinued: PropTypes.bool,
    shareImage: PropTypes.object,
    eyebrow: PropTypes.string.isRequired,
    pageDescription: PropTypes.string.isRequired,
    category: PropTypes.object.isRequired,
    slug: PropTypes.string.isRequired,
    productGroup: PropTypes.object,
    textColor: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    image: PropTypes.object,
    calloutEyebrow: PropTypes.string,
    callout: PropTypes.string,
    nutritionInfo: PropTypes.array.isRequired,
    allergens: PropTypes.string,
    award: PropTypes.object,
    awardAsset: PropTypes.object,
    hideWhereToBuyButton: PropTypes.bool,
    icon: PropTypes.string,
    sizes: PropTypes.array,
    sizes3: PropTypes.array,
    groupThumbnail: PropTypes.object,
    groupLabel: PropTypes.string,
    ingredients: PropTypes.array,
    tillamapsUrl: PropTypes.string,
    shopProduct: PropTypes.object,
    theme: PropTypes.string,
    seoTitle: PropTypes.string,
    shareTitle: PropTypes.string,
    metaDescription: PropTypes.string,
    shareDescription: PropTypes.string,
    relatedPages: PropTypes.object,
    blocks: PropTypes.array,
};

export default Product;
