'use client';

import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import { useTheme } from 'hooks/useTheme';

import { Disclosure } from './Disclosure';

import styles from './Accordion.module.scss';

export { default as Child } from './Child';

const Accordion = ({
    theme,
    children,
    className,
    animationOptions = {
        duration: 300,
        easing: 'cubic-bezier(0.65 ,0.05 ,0.36 , 1)',
    },
}) => {
    const [globalTheme] = useTheme();

    return (
        <Disclosure.Root
            className={cx(
                styles.root,
                className,
                styles[`theme--${globalTheme}`],
                theme && styles[`theme--${theme}`]
            )}
            animationOptions={animationOptions}
        >
            {children}
        </Disclosure.Root>
    );
};

Accordion.propTypes = {
    animationOptions: PropTypes.object,
    children: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node),
    ]).isRequired,
    className: PropTypes.string,
    duration: PropTypes.number,
    easing: PropTypes.string,
    theme: PropTypes.string,
};

export default Accordion;
